// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Logo/images/marker-logo-06.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Customize the Clustered Markers */
.leaflet-marker-icon {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-size: cover;
  /* background: #9370db; */
  /* border: 3px solid  #ededed; */
  border-radius: 50%;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
  /* left: -12px; */
  
}
.leaflet-marker-icon > div > span {
  font-weight: 900;
  color: black;
  font-size: 2.5vmin;
  position: relative;
  /* bottom: 0.3vmin; */
}
.leaflet-marker-pane >img {
  background-image: none;
}
`, "",{"version":3,"sources":["webpack://./src/style/style.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,yDAA0D;EAC1D,2BAA2B;EAC3B,sBAAsB;EACtB,yBAAyB;EACzB,gCAAgC;EAChC,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,iBAAiB;;AAEnB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,sBAAsB;AACxB","sourcesContent":["/* Customize the Clustered Markers */\r\n.leaflet-marker-icon {\r\n  background-image: url(\"../Logo/images/marker-logo-06.png\");\r\n  background-position: center;\r\n  background-size: cover;\r\n  /* background: #9370db; */\r\n  /* border: 3px solid  #ededed; */\r\n  border-radius: 50%;\r\n  height: 40px;\r\n  line-height: 37px;\r\n  text-align: center;\r\n  width: 40px;\r\n  /* left: -12px; */\r\n  \r\n}\r\n.leaflet-marker-icon > div > span {\r\n  font-weight: 900;\r\n  color: black;\r\n  font-size: 2.5vmin;\r\n  position: relative;\r\n  /* bottom: 0.3vmin; */\r\n}\r\n.leaflet-marker-pane >img {\r\n  background-image: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
